<template>
    <div>
      <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
      <b-card no-body class="mb-0">
        <div class="m-2">
          <!-- Table Top -->          
          <b-row class="justify-content-between mt-1">
            <b-col cols="12" lg="6" class="d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-sm-start align-items-center">
              <h2 class=" table-top-title">{{"Retro Calculation".toUpperCase()}}</h2>
              <textSlider :type="searchTypeFilter" :year-filter.sync="yearFilter" :quarter-filter.sync="quarterFilter" ></textSlider>
            </b-col>
            <b-col sm="6" lg="4" class=" d-flex justify-content-sm-start mt-1 mt-lg-0" >
               <b-button-group class="mobile-w100 primary">
                <b-button @click="searchTypeFilter='annual'">Annual</b-button>
                <b-button @click="searchTypeFilter='biAnnual'">Bi-annual</b-button>
                <b-button @click="searchTypeFilter='quarter'" >Quarter</b-button>
              </b-button-group>
            </b-col>
            <b-col sm="6" lg="2" class="d-flex justify-content-sm-end  mt-1 mt-lg-0">
              <b-button v-on:click="onBtnExport()" class="btn-primary-green btn-trigger-export mobile-w100">
                    <feather-icon icon="FileTextIcon" class="mr-1" size="16" />
                    Export
              </b-button>
            </b-col>
          </b-row>
          <div class="d-flex flex-wrap align-items-center justify-content-between mt-2">
           
            <!-- Currency -->
              <div class="currency-rate flex-wrap">
                <div class="title" :class="currencyInfo ? 'hasInfo' : '' " >
                  Currency Rate:
                </div>
                <div v-if="currencyInfo">
                  <div  class="d-flex flex-wrap">
                    <div v-for = "(curr,index) in currencyInfo.rates" :key="'currency'+index">
                      <div v-if="curr.currency.currency!='USD'" class="currency-rate__box">
                        <div class="change-from">
                          {{curr.currency.currency}}
                        </div>
                        <span>to USD</span>
                        <b-form-input type="number" v-model="curr.usd_exchange_rate"></b-form-input>
                      </div>
                    </div>
                  </div> 
                  <div class="d-flex w-100 pb-1 px-1 justify-content-start">
                      <b-button  
                      v-if="ability.can('update', 'retrocession')" :disabled="loading" class="px-3 mt-1 mt-lg-0 mobile-w100" variant="primary" @click="changeCurrency">change</b-button>
                  </div>
                </div> 
                <div v-else>
                  <p class="mb-0 p-2 font-weight-bolder" style="line-height:1.7">No matching records found</p>
                </div>
               
              </div>
            <!-- Add new record -->
                  <div class="d-flex add-record-wrapper align-items-center justify-content-center justify-content-md-end">
                  
                    <b-button
                      @click="addRetrocession"
                      v-if="ability.can('create', 'retrocession')"
                      variant="primary"
                      :to="{ name: 'retrocession-create' }"
                      class="mobile-w100"
                    >
                      <span class="text-nowrap" style="line-height:1.2">
                      <feather-icon icon="PlusIcon" style="margin-right:10px;" size="18" />Add New Record</span>
                    </b-button>
                  </div>
          </div>
        </div>
        <b-table
          ref="refRetrocessionListTable"
          class="position-relative"
          :items="fetchRetrocession"
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          responsive
          primary-key="id"
          show-empty
          empty-text="No matching records found"
        >
  
          <!-- Column: Actions -->
          <template #cell(amount)="data">
            <p class="mb-0">
              {{data.item.amount}}
            </p>
            <span class="change-from">
              {{data.item.currency_name}}
            </span>
          </template>
          <!-- <template #cell(usd)="data"> -->
               <!-- {{ data.item.usd_exchange_rate == 0 ? data.item.amount.toFixed(2) : (data.item.amount * data.item.usd_exchange_rate).toFixed(2) }} -->
          <!-- </template> -->
          <template #cell(actions)="data">
          <b-dropdown class="action-trigger-btn" :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="EyeIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item
              v-if="ability.can('update', 'retrocession')"
              :to="{ name: 'retrocession-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="ability.can('delete', 'retrocession')"  @click="handleDelete(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        </b-table>
        <div class="mx-2 pt-1 d-flex total-box justify-content-between">
          <p class="title">GRAND TOTAL</p>
          <p class="amount">${{totalAmount}}</p>
        </div>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
            >
              <span class="text-muted">
                Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries
                </span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
            >
              <b-pagination
                first-number
                last-number
                v-model="currentPage"
                :total-rows="totalRetrocession"
                :per-page="perPage"
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
      </b-overlay>
    </div>
</template>
  
<script>

  import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BButtonGroup,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    VBToggle,
    BOverlay,
  } from "bootstrap-vue";

  // import axiosIns from '@/libs/axios'
  import vSelect from "vue-select";
  import store from "@/store";
  import { ref, onUnmounted, onMounted } from "@vue/composition-api";
  import useRetrocessionList from "./useRetrocessionList";
  import textSlider from "@/components/slider/textSlider.vue";
  import retrocessionStoreModule from "../retrocessionStoreModule";
  export default {
    components: {
      BOverlay,
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BButtonGroup,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      vSelect,
      textSlider,
    },
    directives: {
      "b-toggle": VBToggle,
    },
  data() {
    return {}
  },
  watch:{
  },
    methods: {
    onBtnExport() {
      // this.gridApi.exportDataAsExcel();
      this.loading = true;
       store
        .dispatch('app-retrocession/exportRetrocession',{'year':this.yearFilter,'quarter':this.quarterFilter,'search_type':this.searchTypeFilter,})
        .then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);
            // create "a" HTLM element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            if(this.searchTypeFilter == "quarter"){
              link.setAttribute('download', `Retrocession Record ${this.yearFilter} Q${this.quarterFilter}`);
            }else if(this.searchTypeFilter == "annual"){
                link.setAttribute('download', `Retrocession Record ${this.yearFilter} Annual`);
            }else if(this.searchTypeFilter == "biAnnual"){
                link.setAttribute('download', `Retrocession Record ${this.quarterFilter > 2 ? this.yearFilter + ' Q3 - Q4' : this.yearFilter + ' Q1 - Q2'}`);
            }
            document.body.appendChild(link);
            link.click();
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        })
        .finally(() => {
          this.loading = false;
        })
        ;
    },
      addRetrocession() {
        this.isAddNewRetrocessionSidebarActive = true;
      },
      handleDelete(id) {
      this.$swal({
        title: "Are you sure?",
        text:
          "Your action is final and you will not be able to retrieve the user record.",
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("app-retrocession/deleteRetrocession", { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.selectBankAc = "";
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
      changeCurrency(){
        let obj;
        const currencyInfo = this.currencyInfo;
        if(currencyInfo){
          if(currencyInfo.id == 0){
            // insert
            obj = {
              id: 0,
              year : currencyInfo.year,
              quarter : currencyInfo.quarter,
              rates : currencyInfo.rates.map((item) => {
                return {'currency_id': item.currency_id,'usd_exchange_rate' : item.usd_exchange_rate*1}
              })
            }
          }else{  
            // update
            obj = {
              id: currencyInfo.id,
              rates : currencyInfo.rates.map((item) => {
                return {'id' : item.id, 'usd_exchange_rate' : item.usd_exchange_rate*1}
              })
            }
          }
          this.$store.dispatch("app-retrocession/updateCurrencyRate", obj)
            .then(response => {
              this.triggerFetch = ! this.triggerFetch
              this.$swal({
                  text: response.data.message,
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
              });
            }).catch((error) => {
                console.log(error)
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error to update currency rate',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                })
            })
        }
      }
    },
    computed:{
      totalAmount(){
        const a = this.retrocessionsInfo.map(item =>{
          if (item.usd_exchange_rate == 0) {
            item.usd_exchange_rate = 1
          }
            return item.amount * item.usd_exchange_rate
        })
        return a.reduce((prev,curr) => prev + curr ,0).toFixed(2);
      },
    },
    setup() {
      
      const APP_CLIENT_STORE_MODULE_NAME = "app-retrocession";
      const isAddNewRetrocessionSidebarActive = ref(false);
  
      if (!store.hasModule(APP_CLIENT_STORE_MODULE_NAME))
        store.registerModule(APP_CLIENT_STORE_MODULE_NAME, retrocessionStoreModule);

      onUnmounted(() => {
        if (store.hasModule(APP_CLIENT_STORE_MODULE_NAME))
          store.unregisterModule(APP_CLIENT_STORE_MODULE_NAME);
      });
      onMounted(()=>{
       
      })

      const {
        loading,
        fetchRetrocession,
        currencyInfo,
        retrocessionsInfo,
        tableColumns,
        perPage,
        currentPage,
        totalRetrocession,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refetchData,
        refRetrocessionListTable,
        ability,
        yearFilter,
        quarterFilter,
        searchTypeFilter,
        triggerFetch,
      } = useRetrocessionList();
      
      let obj = {}
      let tbc = [...tableColumns]
      const res = tbc.forEach(element => {
        if(element.key == 'amount'){
          obj[element.label] = {
            field: element.key,
            callback: (value) => {
              return `${value.amount}\n${value.curr}`;
            },
          }
        }else{
           obj[element.label] = element.key
        } 
      });
      let retrocession_fields = ref(obj);

      return {
        loading,
        triggerFetch,
        currencyInfo,
        retrocessionsInfo,
        retrocession_fields,
        isAddNewRetrocessionSidebarActive,
        fetchRetrocession,
        tableColumns,
        perPage,
        currentPage,
        totalRetrocession,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refetchData,
        refRetrocessionListTable,
        ability,

        yearFilter,
        quarterFilter,
        searchTypeFilter,
      };
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  .badge {
    display: block;
    margin: 5px;
    max-width: 200px;
  }
  .btn-trigger-export{
    float:left
  }
  </style>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@/assets/scss/variables/_variables.scss";

  .btn-group .btn{
    white-space: nowrap
  }
  .table-top-title{
    // line-height: 2;
    align-items: center;
    margin-bottom: 0;
    white-space:nowrap;
    font-size: 1.4rem;
  }
  

  .currency-rate{
    display: flex;
    position: relative;
    padding: 0.2rem 1.5rem;
    border-radius: 6px;
    // align-items: center;
    background-color:$primary-color-5;
    width: 81%;
    overflow-x: auto;
    &__box{
      display: flex;
      align-items: center;
      margin: 1rem;
      white-space: nowrap;
      input{
          width: 8rem;
          margin-left: 1rem;
      }
    }
    .title{
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color:$primary-color-4;
      white-space: nowrap;
      margin: 1.5rem 1rem;
      &.hasInfo{
        margin: 1.5rem 1rem 0;
      }
    }
  }
    .change-from{
      color:#fff;
      background-color:$primary-color-1;
      border-radius:25px;
      padding: 0px 8px;
      margin-right: 5px;
    }
    .total-box{
      border-top:1px solid $primary-color-4;
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
    }
    .trigger-rate-list{
      position: absolute;
      top: 1rem;
      right: 2rem;
    }

// @media  (min-width:1400px) and (max-width:1800px) {

//   .currency-rate{
//     padding:0.2rem 1rem 1rem;
//     .title{
//       padding: 1rem 0rem 0rem
//     }
//   }
// }
@media screen and (max-width:1400px) {
  .currency-rate{
    width: 70%;
    // .title{
    //   padding: 1rem 0rem 0rem
    // }
    &__box{
      margin: 0.5rem 1rem;
    }
  }
}

@media screen and (max-width:768px) {
  .currency-rate{
    width:100%;
    margin-bottom: 1.5rem;
  }
  .add-record-wrapper {
    width:100%;
  }
}
</style>
  